<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Start date"
            label-for="StartDate"
          >
            <b-form-datepicker
              id="StartDate"
              v-model="StartDate"
              placeholder="Start date"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="End Date"
            label-for="EndDate"
          >
            <b-form-datepicker
              id="EndDate"
              v-model="EndDate"
              placeholder="End Date"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Membership Number"
            label-for="MembershipNumber"
          >
            <b-form-input
              id="MembershipNumber"
              v-model="MembershipNumber"
              placeholder="Membership Number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Redeem Gift 1"
            label-for="RedeemGift1"
          >
            <v-select
              id="RedeemGift1"
              v-model="RedeemGift1"
              :clearable="false"
              :options="RedeemOptions"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Redeem Gift 1 Time"
            label-for="RedeemGift1Time"
          >
            <b-form-datepicker
              id="RedeemGift2Time"
              v-model="RedeemGift2Time"
              placeholder="Redeem Gift 2 Time"
              class="mb-1"
            />
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Redeem Gift 2"
            label-for="RedeemGift2"
          >
            <v-select
              id="RedeemGift2"
              v-model="RedeemGift2"
              :clearable="false"
              :options="RedeemOptions"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Redeem Gift 2 Time"
            label-for="RedeemGift2Time"
          >
            <b-form-datepicker
              id="RedeemGift2Time"
              v-model="RedeemGift2Time"
              placeholder="Redeem Gift 2 Time"
              class="mb-1"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            @click="SearchData()"
          >
            <span class="text-nowrap">Search Data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
  BFormGroup, BFormInput,
  BButton,
  BFormDatepicker,
  // BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    BFormDatepicker,
    // BFormCheckbox,
    vSelect,
  },
  props: {
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    endDateFilter: {
      type: [String, null],
      default: null,
    },
    orderCodeNumberFilter: {
      type: [String, null],
      default: null,
    },
    // roleOptions: {
    //   type: Array,
    //   required: true,
    // },
    // planOptions: {
    //   type: Array,
    //   required: true,
    // },
    // statusOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      StartDate: '',
      EndDate: '',
      MembershipNumber: '',
      RedeemGift1: 'All',
      // RedeemGift1Time: '',
      RedeemGift2: 'All',
      // RedeemGift2Time: '',
      RedeemOptions: [
        'All',
        'Yes',
        'No',
      ],
    }
  },
  methods: {
    SearchData() {
      this.$emit('update:startDateFilter', this.StartDate)
      this.$emit('update:endDateFilter', this.EndDate)
      this.$emit('update:membershipNumberFilter', this.MembershipNumber)
      this.$emit('update:redeemGift1Filter', this.RedeemGift1)
      // this.$emit('update:redeemGift1TimeFilter', this.RedeemGift1Time)
      this.$emit('update:redeemGift2Filter', this.RedeemGift2)
      // this.$emit('update:redeemGift2TimeFilter', this.RedeemGift2Time)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
