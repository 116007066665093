import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomersList() {
  // Use toast
  const toast = useToast()

  const refCustomerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'MembershipNumber', sortable: false },
    { key: 'FirstLoginTime', sortable: false },
    { key: 'AreaCode', sortable: false },
    { key: 'Mobile', sortable: false },
    { key: 'Email', sortable: false },
    { key: 'UpdateDateTime', sortable: false },
    { key: 'RedeemGift1', sortable: false },
    { key: 'RedeemGift1Time', sortable: false },
    { key: 'RedeemGift2', sortable: false },
    { key: 'RedeemGift2Time', sortable: false },
    { key: 'RecycleStamp', sortable: false },
    { key: 'UpdatedBy', sortable: false },
    { key: 'Remark', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const StartDateFilter = ref('')
  const EndDateFilter = ref('')
  const MembershipNumberFilter = ref('')
  const RedeemGift1Filter = ref(false)
  const RedeemGift1TimeFilter = ref('')
  const RedeemGift2Filter = ref(false)
  const RedeemGift2TimeFilter = ref('')
  const ShowLoading = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, StartDateFilter, EndDateFilter, MembershipNumberFilter, RedeemGift1Filter, RedeemGift1TimeFilter, RedeemGift2Filter, RedeemGift2TimeFilter], () => {
    refetchData()
  })

  const fetchData = (ctx, callback) => {
    store
      .dispatch('app-report/fetchLog', {
        // q: searchQuery.value,
        PerPage: perPage.value,
        Page: currentPage.value,
        // sortBy: sortBy.value,
        // sortDesc: isSortDirDesc.value,
        StartDate: StartDateFilter.value,
        EndDate: EndDateFilter.value,
        MembershipNumber: MembershipNumberFilter.value,
        RedeemGift1: RedeemGift1Filter.value,
        // RedeemGift1Time: RedeemGift1TimeFilter.value,
        RedeemGift2: RedeemGift2Filter.value,
        // RedeemGift2Time: RedeemGift2TimeFilter.value,
      })
      .then(response => {
        const { Data, Total } = response.data

        callback(Data)
        totalCustomers.value = Total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadReport = () => {
    ShowLoading.value = true
    store
      .dispatch('app-report/downloadLogReport', {
        StartDate: StartDateFilter.value,
        EndDate: EndDateFilter.value,
        MembershipNumber: MembershipNumberFilter.value,
        RedeemGift1: RedeemGift1Filter.value,
        // RedeemGift1Time: RedeemGift1TimeFilter.value,
        RedeemGift2: RedeemGift2Filter.value,
        // RedeemGift2Time: RedeemGift2TimeFilter.value,
      })
      // .then(response => {
      //   const { Data, Total } = response.data

      //   callback(Data)
      //   totalCustomers.value = Total
      // })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        ShowLoading.value = false
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCustomerRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveCustomerRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchData,
    downloadReport,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,
    ShowLoading,

    resolveCustomerRoleVariant,
    resolveCustomerRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    StartDateFilter,
    EndDateFilter,
    MembershipNumberFilter,
    RedeemGift1Filter,
    RedeemGift1TimeFilter,
    RedeemGift2Filter,
    RedeemGift2TimeFilter,
  }
}
