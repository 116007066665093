<template>
  <div>
    <b-overlay
      :show="ShowLoading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <!-- Filters -->
      <report-list-filters
        :start-date-filter.sync="StartDateFilter"
        :end-date-filter.sync="EndDateFilter"
        :membership-number-filter.sync="MembershipNumberFilter"
        :redeem-gift-1-filter.sync="RedeemGift1Filter"
        :redeem-gift-1-time-filter.sync="RedeemGift1TimeFilter"
        :redeem-gift-2-filter.sync="RedeemGift2Filter"
        :redeem-gift-2-time-filter.sync="RedeemGift2TimeFilter"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <!-- Download -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <b-button
                @click="DownloadReport"
              >
                Download
              </b-button>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refCustomerListTable"
          class="position-relative"
          :items="fetchData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- <b-dropdown-item
              :to="{ name: 'apps-Customers-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->

              <b-dropdown-item
                :to="{ name: 'customer-edit', params: { id: data.item.ID } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

            <!-- <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
            >
              <span
                class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalCustomers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  // BFormInput,
  BButton,
  BTable,
  // BMedia,
  // BAvatar,
  // BLink,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// import { avatarText } from '@core/utils/filter'
import ReportListFilters from './ReportListFilters.vue'
import useReportList from './useReportList'
import ReportStoreModule from '../reportStoreModule'

export default {
  components: {
    ReportListFilters,

    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
  setup() {
    const REPORT_APP_STORE_MODULE_NAME = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) { store.registerModule(REPORT_APP_STORE_MODULE_NAME, ReportStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) { store.unregisterModule(REPORT_APP_STORE_MODULE_NAME) }
    })

    const isAddNewCustomerSidebarActive = ref(false)

    const areaCodeOptions = [
      { label: '+852', value: '+852' },
      { label: '+853', value: '+853' },
    ]

    const {
      fetchData,
      downloadReport,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,
      ShowLoading,

      // UI
      // resolveCustomerRoleVariant,
      // resolveCustomerRoleIcon,
      // resolveCustomerStatusVariant,

      // Extra Filters
      StartDateFilter,
      EndDateFilter,
      MembershipNumberFilter,
      RedeemGift1Filter,
      RedeemGift1TimeFilter,
      RedeemGift2Filter,
      RedeemGift2TimeFilter,
    } = useReportList()

    return {
      // Sidebar
      isAddNewCustomerSidebarActive,

      fetchData,
      downloadReport,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,
      ShowLoading,

      // Filter
      // avatarText,

      // UI
      // resolveCustomerRoleVariant,
      // resolveCustomerRoleIcon,
      // resolveCustomerStatusVariant,

      // roleOptions,
      // planOptions,
      // statusOptions,
      areaCodeOptions,

      // Extra Filters
      StartDateFilter,
      EndDateFilter,
      MembershipNumberFilter,
      RedeemGift1Filter,
      RedeemGift1TimeFilter,
      RedeemGift2Filter,
      RedeemGift2TimeFilter,
    }
  },
  methods: {
    DownloadReport() {
      this.downloadReport()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
